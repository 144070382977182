<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">精品课程</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">轮播图设置</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:5px">
            <div title="banner名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">banner名称:</span>
              <el-input
                v-model="name"
                type="text"
                size="small"
                clearable
                placeholder="请输入banner名称"
              />
            </div>
            <div title="是否显示" class="searchboxItem ci-full">
              <span class="itemLabel">是否显示:</span>
              <el-select
                clearable
                size="small"
                v-model="isView"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in openList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="btnBox">
              <el-button
                style="margin-left:20px"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
              <el-button class="bgc-bv" round @click="handleAdd('add')"
                >新增</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              @cell-mouse-enter="M_Enter"
              @cell-mouse-leave="m_leave"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="banner名称"
                align="left"
                prop="name"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column label="是否显示" align="center" min-width="100">
                <div slot-scope="scope" class="table-switch">
                  <el-switch
                    :width="20"
                    v-model="scope.row.display"
                    active-color="#13ce66"
                    @change="handleStu(scope.row.carouselId, scope.row.display)"
                  ></el-switch>
                </div>
              </el-table-column>
              <el-table-column label="排序" align="center" min-width="100">
                <!--  -->
                <template slot-scope="scope">
                  <div v-if="!scope.row.orderShow">
                    <span> {{ scope.row.order }}</span>
                    <i
                      class="el-icon-edit"
                      v-show="scope.row.showInput"
                      @click="changeOrder(scope.row)"
                    ></i>
                  </div>
                  <el-input
                    v-else
                    v-model="scope.row.order"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    @blur="
                      () => {
                        editRowOrder(scope.row);
                      }
                    "
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column label="上传时间" align="center" min-width="100">
                <!--  -->
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="140px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="handleEdit('edit', scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="handleDel(scope.row.carouselId)"
                    >删除</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="DialogVisible"
      width="75%"
      center
      top="1.5%"
      @close="doClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="110px"
        class="demo-ruleForm"
      >
        <el-form-item label="banner名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            size="small"
            maxlength="30"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传banner" required prop="thumbnail">
          <el-upload
            :on-change="handleAvatarSuccess2"
            :before-upload="beforeAvatarUpload"
            :http-request="$requestMine"
            :show-file-list="false"
            class="img-el-upload"
            action
            accept="image/png, image/gif, image/jpg, image/jpeg"
            :disabled="disabled"
          >
            <el-image
              :src="ruleForm.thumbnail || require('@/assets/develop.png')"
              fit="contain"
              class="imgCenter"
            ></el-image>
          </el-upload>
        </el-form-item>
        <el-form-item label="跳转目标" prop="jumpType">
          <el-radio-group v-model="ruleForm.jumpType" @change="radioChange">
            <el-radio :label="1">链接</el-radio>
            <!-- <el-radio :label="2">应用</el-radio> -->
            <el-radio :label="2">课程</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="上传活动图片"
          required
          v-if="ruleForm.jumpType == 1"
          prop="thumbnailList"
        >
          <el-upload
            action
            :file-list="ruleForm.fileList"
            list-type="picture-card"
            :on-change="handlePictureCardPreview"
            :before-upload="beforeAvatarUpload2"
            :http-request="$requestMine"
            limit="5"
            class="df"
            :class="{ hide: hideUploadCard }"
            :on-remove="handleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="标题" prop="title" v-if="ruleForm.jumpType == 1">
          <el-input v-model="ruleForm.title" size="small"></el-input>
        </el-form-item>
        <!-- <el-form-item
          label="按钮"
          prop="buttonName"
          v-if="ruleForm.jumpType == 1"
        >
          <el-input v-model="ruleForm.buttonName" size="small"></el-input>
        </el-form-item> -->
        <el-form-item
          label="选择课程"
          prop="course"
          v-if="ruleForm.jumpType == 2"
        >
          <el-select
            v-model="ruleForm.course"
            placeholder="请选择课程"
            size="small"
            clearable
            filterable
          >
            <el-option
              v-for="item in courseList"
              :key="item.courseId"
              :label="item.courseName"
              :value="item.courseId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否显示" required>
          <el-switch
            class="dialog-switch"
            v-model="ruleForm.display"
          ></el-switch>
        </el-form-item>
        <el-form-item label="排序" prop="order">
          <el-input
            v-model="ruleForm.order"
            size="small"
            oninput="value=value.replace(/[^\d]/g,'')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div style="display:flex;justify-content: center">
        <el-button @click="doClose">取消</el-button>
        <el-button class="bgc-bv" @click="submitForm('ruleForm')"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "course_jp/swipperList",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    let validatefileList = (rule, value, callback) => {
      // console.log(this.ruleForm.fileList);
      if (value.length > 0) {
        callback();
      } else {
        callback(new Error("请上传活动图片"));
      }
    };
    return {
      DialogVisible: false,
      hideUploadCard: false,
      title: "",
      name: "",
      isView: "",
      openList: [
        // 是否显示
        {
          label: "全部",
          value: ""
        },
        {
          label: "显示",
          value: true
        },
        {
          label: "隐藏",
          value: false
        }
      ],
      ruleForm: {
        thumbnail: "",
        srcImg: [],
        thumbnailList: [],
        fileList: [],
        display: false,
        order: "",
        title: "",
        jumpType: 1,
        course: ""
      },
      rules: {
        name: [
          { required: true, message: "请输入banner图名称", trigger: "blur" }
        ],
        thumbnail: [
          { required: true, message: "请上传图片", trigger: "change" }
        ],
        jumpType: [
          { required: true, message: "请选择跳转目标", trigger: "change" }
        ],
        course: [
          { required: true, message: "请选择课程", trigger: "change" }
        ],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        thumbnailList: [
          {
            required: true,
            message: "请上传课程简介",
            trigger: "blur",
          },
        ],
        order: [{ required: true, message: "请输入顺序", trigger: "blur" }]
      },
      stu: "add",
      courseList: []
    };
  },
  computed: {},
  created() {
    this.getTableHeight();
    this.getcourseList();
  },
  methods: {
    handleListDataBack(data) {
      this.tableData = [];
      data.map(el => {
        el.showInput = false;
        el.orderShow = false;
        this.tableData.push(el);
      });
    },
    // 鼠标进入事件
    M_Enter(row, column, cell, event) {
      if (column.label == "排序") {
        row.showInput = true;
      }
    },
    // 鼠标离开事件
    m_leave(row, column, cell, event) {
      row.showInput = false;
    },
    // 点击笔
    changeOrder(row) {
      row.orderShow = !row.orderShow;
    },
    //输入框失去焦点 保存修改
    editRowOrder(row) {
      const params = {
        order: row.order,
        carouselId: row.carouselId
      };
      this.$post("/biz/ec/carousel/modify", params, 3000, true, 2).then(res => {
        this.$message({
          type: "success",
          message: "修改成功"
        });
        this.getData(-1);
      });
    },
    radioChange() {
      if (this.jumpType == 2) {
        this.ruleForm.title = "";
        this.ruleForm.thumbnailList = []
      } else {
        this.ruleForm.course = "";
      }
    },
    // //获取分类
    // getapplicationList() {
    //   this.$post("/biz/ec/carousel/feature/list")
    //     .then(ret => {
    //       this.applicationList = ret.data || [];
    //     })
    //     .catch(err => {
    //       return;
    //     });
    // },
    getcourseList() {
      let parmar = {}
      this.$post("/biz/ec/course/list",{}, 3000, true, 2)
        .then(ret => {
          this.courseList = ret.data || [];
        })
        .catch(err => {
          return;
        });
    },

    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        display: this.isView
      };
      if (this.name) {
        params.name = this.name;
      }
      this.doFetch({
        url: "/biz/ec/carousel/listPage",
        params,
        pageNum
      },true,2);
    },
    handleStu(carouselId, display) {
      this.$post("/biz/ec/carousel/modify/display", {
        carouselId,
        display
      }, 3000, true, 2)
        .then(ret => {
          if (ret.status == "0") {
            this.$message({
              message: ret.message,
              type: "success"
            });
          }
          // console.log(ret);
        })
        .catch(err => {
          return;
        });
    },
    handleAdd(stu) {
      this.stu = stu;
      this.title = "新增轮播图";
      this.DialogVisible = true;
    },
    // 修改
    handleEdit(stu, row) {
      // ;
      this.stu = stu;
      this.title = "修改轮播图";
      let jumpData = JSON.parse(row.jumpData);
      this.ruleForm = {
        ...this.ruleForm,
        ...row,
        thumbnail: row.bannerKey,
        title: jumpData.title,
        buttonName: jumpData.buttonName,
        carouselId: row.carouselId,
        bannerKey: "",
        thumbnailList: jumpData.picList || [],
        display: row.display,
        jumpType: row.jumpType
      };
      if (row.jumpType == 1) {
        for (var i = 0; i < jumpData.picList.length; i++) {
          this.ruleForm.fileList.push({
            name: jumpData.picList[i].picPath,
            url: jumpData.picList[i].picPathUrl,
            width: jumpData.picList[i].width,
            height: jumpData.picList[i].height
          });
        }
      }
      if (row.jumpType == 2) {
        this.ruleForm.course = jumpData.courseId;
      }
      this.DialogVisible = true;
    },
    submitForm(formName) {
      if (this.ruleForm.jumpType == 2) {
        this.$refs[formName].clearValidate(["title", "jumpType",]);
      } else {
        this.$refs[formName].clearValidate("course");
      }
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.doAjax();
        }
      });
    },
    doAjax() {
      let params = {
        name: this.ruleForm.name,
        bannerKey: this.ruleForm.bannerKey,
        jumpType: this.ruleForm.jumpType,
        display: this.ruleForm.display,
        order: this.ruleForm.order
      };

      if (this.ruleForm.jumpType == 1) {
        params.jumpData = JSON.stringify({
          title: this.ruleForm.title,
          picList: this.ruleForm.thumbnailList
        });
      } else {
        params.jumpData = JSON.stringify({
          courseId: this.ruleForm.course
        });
      }
      if (this.stu == "edit") {
        params.carouselId = this.ruleForm.carouselId;
      }
      this.$post(
        this.stu == "add"
          ? "/biz/ec/carousel/insert"
          : "/biz/ec/carousel/modify",
        params, 3000, true, 2
      )
        .then(ret => {
          if (ret.status == "0") {
            this.$message({
              message: ret.message,
              type: "success"
            });
            this.doClose();
            this.getData();
          }
        })
        .catch(err => {
          return;
        });
    },
    doClose() {
      this.DialogVisible = false;
      // this.$refs["ruleForm"].resetFields();
      this.ruleForm = {
        thumbnail: "",
        srcImg: [],
        thumbnailList: [],
        fileList: [],
        display: false,
        order: "",
        buttonName: "",
        title: "",
        jumpType: 1,
        application: ""
      };
    },
    //删除
    handleDel(carouselId) {
      this.doDel({
        url: "/biz/ec/carousel/delete",
        msg: "你确定要删除该轮播图吗？",
        ps: {
          type: "post",
          data: { carouselId }
        }
      },true,2);
    },
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then(result => {
            this.ruleForm.thumbnail = result.data.fileURL;
            this.ruleForm.bannerKey = result.data.fileKey || "";
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败"
              });
            }, 300);
          });
      }
    },
    beforeAvatarUpload2(file) {
      let that = this;
      var reader = new FileReader();
      reader.onload = function(event) {
        var txt = event.target.result;
        var img = document.createElement("img");
        img.src = txt;
        img.onload = function() {
          that.ruleForm.width = img.width;
          that.ruleForm.height = img.height;
        };
      };
      reader.readAsDataURL(file);
    },
    /* 上传图片start */
    handlePictureCardPreview(res, fileList) {
      const that = this;
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("file ", res.raw);
      formData.append("folder ", "COURSE_WARE");
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then(result => {
            this.ruleForm.thumbnailList.push({
              width: this.ruleForm.width,
              height: this.ruleForm.height,
              picPath: result.data.fileKey,
              picPathUrl: result.data.fileURL
            });
            // console.log(this.ruleForm.thumbnailList);
            this.hideUploadCard =
              this.ruleForm.thumbnailList.length == 5 ? true : false;
          })
          .catch(() => {
            return;
          });
      }
    },
    /* 上传图片end */
    /* 删除图片start */
    handleRemove(res, fileList) {
      // console.log("---", this.ruleForm.thumbnailList);
      this.ruleForm.thumbnailList = [];
      fileList.map(item => {
        this.ruleForm.thumbnailList.push({
          picPath: item.name,
          picPathUrl: item.url,
          width: item.width,
          height: item.height
        });
      });
      this.hideUploadCard = fileList.length == 5 ? true : false;
    },
    /* 删除图片end */
    //
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  }
};
</script>
<style lang="less">
.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}
.dialog-switch {
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
}
.el-form-item__content .hide /deep/.el-upload--picture-card {
  display: none;
}
</style>
